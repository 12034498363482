
class Configuration {
    static B2B_PARTNER_NAME = 'rivkin'; // Define client here (rivkin, akbank, qnb)

    static configMapping = {
        rivkin: {
            PUSHTRADE_API_URL: "https://api-pushtrade.rivkin.com.au/api/",
            B2B_PARTNER_LANGUAGE: 'en',
            B2B_PARTNER_TIMEZONE: 'Australia/Sydney',
            B2B_PARTNER_LOCALE: 'en-AU',
            FEATURE_ENABLED_PORTFOLIO_CURRENCY: false,
            FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY: true,
            FEATURE_ENABLED_CANCEL_ORDER: false,
            FEATURE_ENABLED_CANCEL_RECOMMENDATION: false,
            FEATURE_ENABLED_PENDING_TAB: true,
            FEATURE_ENABLED_SL_TP: false,
            FEATURE_ENABLED_PASSWORD_ON_SUBMIT: false,
            FEATURE_ENABLED_ACCEPT_BY_DEALER: false,
            FEATURE_ENABLED_ACCOUNTS_REPORT: false,
            FEATURE_ENABLED_QNB_CUSTOMER_NUMBER: false,
            FEATURE_ENABLED_MANUAL_ACCOUNTS: true,
            // following variables should turn into roles/permissions
            FEATURE_ENABLED_CUD_CLIENT: true,
            FEATURE_ENABLED_CUD_PORTFOLIO: true,
            FEATURE_ENABLED_CUD_STOCK: true,
            FEATURE_ENABLED_CUD_TRADE: true,
            FEATURE_ENABLED_CUD_ORDER: true,
            FEATURE_ENABLED_PUSHTRADE_ACTIONS: true,
        },
        akbank: {
            PUSHTRADE_API_URL: "https://staging-api-pushtrade.rivkin.com.au/api/",
            B2B_PARTNER_LANGUAGE: 'tr2',
            B2B_PARTNER_TIMEZONE: 'Europe/Istanbul',
            B2B_PARTNER_LOCALE: 'tr-TR',
            FEATURE_ENABLED_PORTFOLIO_CURRENCY: false,
            FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY: false,
            FEATURE_ENABLED_CANCEL_ORDER: false,
            FEATURE_ENABLED_CANCEL_RECOMMENDATION: false,
            FEATURE_ENABLED_PENDING_TAB: false,
            FEATURE_ENABLED_SL_TP: false,
            FEATURE_ENABLED_PASSWORD_ON_SUBMIT: true,
            FEATURE_ENABLED_ACCEPT_BY_DEALER: true,
            FEATURE_ENABLED_ACCOUNTS_REPORT: true,
            FEATURE_ENABLED_QNB_CUSTOMER_NUMBER: false,
            FEATURE_ENABLED_MANUAL_ACCOUNTS: false,
            FEATURE_ENABLED_ADD_CLIENT: true,
            FEATURE_ENABLED_ADD_PORTFOLIO: true,
            // following variables should turn into roles/permissions
            FEATURE_ENABLED_CUD_CLIENT: true,
            FEATURE_ENABLED_CUD_PORTFOLIO: true,
            FEATURE_ENABLED_CUD_STOCK: true,
            FEATURE_ENABLED_CUD_TRADE: true,
            FEATURE_ENABLED_CUD_ORDER: true,
            FEATURE_ENABLED_PUSHTRADE_ACTIONS: true,
        },
        qnb: {
            PUSHTRADE_API_URL: "https://qnb-staging-api.qnbfi.com/api/",
            B2B_PARTNER_LANGUAGE: 'tr3',
            B2B_PARTNER_TIMEZONE: 'Europe/Istanbul',
            B2B_PARTNER_LOCALE: 'tr-TR',
            FEATURE_ENABLED_PORTFOLIO_CURRENCY: true,
            FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY: false,
            FEATURE_ENABLED_CANCEL_ORDER: false,
            FEATURE_ENABLED_CANCEL_RECOMMENDATION: true,
            FEATURE_ENABLED_PENDING_TAB: false,
            FEATURE_ENABLED_SL_TP: true,
            FEATURE_ENABLED_PASSWORD_ON_SUBMIT: true,
            FEATURE_ENABLED_ACCEPT_BY_DEALER: false,
            FEATURE_ENABLED_ACCOUNTS_REPORT: false,
            FEATURE_ENABLED_QNB_CUSTOMER_NUMBER: true,
            FEATURE_ENABLED_MANUAL_ACCOUNTS: false,
            FEATURE_ENABLED_ADD_CLIENT: false,
            FEATURE_ENABLED_ADD_PORTFOLIO: false,
            // following variables should turn into roles/permissions
            FEATURE_ENABLED_CUD_CLIENT: false,
            FEATURE_ENABLED_CUD_PORTFOLIO: false,
            FEATURE_ENABLED_CUD_STOCK: false,
            FEATURE_ENABLED_CUD_TRADE: false,
            FEATURE_ENABLED_CUD_ORDER: false,
            FEATURE_ENABLED_PUSHTRADE_ACTIONS: false,
        }
    };

    static B2B_PARTNER_LOGO = `${Configuration.B2B_PARTNER_NAME}-logo.png`;
    static B2B_PARTNER_AVATAR = `${Configuration.B2B_PARTNER_NAME}-avatar.ico`;
    static B2B_PARTNER_FAVICON = `${Configuration.B2B_PARTNER_NAME}-favicon.ico`;
    static PUSHTRADE_API_URL = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].PUSHTRADE_API_URL;
    static B2B_PARTNER_LANGUAGE = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].B2B_PARTNER_LANGUAGE;
    static B2B_PARTNER_TIMEZONE = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].B2B_PARTNER_TIMEZONE;
    static B2B_PARTNER_LOCALE = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].B2B_PARTNER_LOCALE;
    static FEATURE_ENABLED_PORTFOLIO_CURRENCY = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_PORTFOLIO_CURRENCY;
    static FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY;
    static FEATURE_ENABLED_CANCEL_ORDER = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_CANCEL_ORDER;
    static FEATURE_ENABLED_CANCEL_RECOMMENDATION = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_CANCEL_RECOMMENDATION;
    static FEATURE_ENABLED_PENDING_TAB = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_PENDING_TAB;
    static FEATURE_ENABLED_SL_TP = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_SL_TP;
    static FEATURE_ENABLED_PASSWORD_ON_SUBMIT = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_PASSWORD_ON_SUBMIT;
    static FEATURE_ENABLED_ACCEPT_BY_DEALER = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_ACCEPT_BY_DEALER;
    static FEATURE_ENABLED_ACCOUNTS_REPORT = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_ACCOUNTS_REPORT;
    static FEATURE_ENABLED_QNB_CUSTOMER_NUMBER = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_QNB_CUSTOMER_NUMBER;
    static FEATURE_ENABLED_MANUAL_ACCOUNTS = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_MANUAL_ACCOUNTS;
    static FEATURE_ENABLED_CUD_CLIENT = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_CUD_CLIENT;
    static FEATURE_ENABLED_CUD_PORTFOLIO = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_CUD_PORTFOLIO;
    static FEATURE_ENABLED_CUD_STOCK = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_CUD_STOCK;
    static FEATURE_ENABLED_CUD_TRADE = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_CUD_TRADE;
    static FEATURE_ENABLED_CUD_ORDER = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_CUD_ORDER;
    static FEATURE_ENABLED_PUSHTRADE_ACTIONS = Configuration.configMapping[Configuration.B2B_PARTNER_NAME].FEATURE_ENABLED_PUSHTRADE_ACTIONS;

    static setPartnerName(partnerName) {
        Configuration.B2B_PARTNER_NAME = partnerName;
        const config = Configuration.configMapping[partnerName];

        Configuration.B2B_PARTNER_LOGO = `${partnerName}-logo.png`;
        Configuration.B2B_PARTNER_AVATAR = `${partnerName}-avatar.ico`;
        Configuration.B2B_PARTNER_FAVICON = `${partnerName}-favicon.ico`;
        Configuration.PUSHTRADE_API_URL = config.PUSHTRADE_API_URL;
        Configuration.B2B_PARTNER_LANGUAGE = config.B2B_PARTNER_LANGUAGE;
        Configuration.B2B_PARTNER_TIMEZONE = config.B2B_PARTNER_TIMEZONE;
        Configuration.B2B_PARTNER_LOCALE = config.B2B_PARTNER_LOCALE;
        Configuration.FEATURE_ENABLED_PORTFOLIO_CURRENCY = config.FEATURE_ENABLED_PORTFOLIO_CURRENCY;
        Configuration.FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY = config.FEATURE_ENABLED_PORTFOLIO_MIXED_CURRENCY;
        Configuration.FEATURE_ENABLED_CANCEL_ORDER = config.FEATURE_ENABLED_CANCEL_ORDER;
        Configuration.FEATURE_ENABLED_CANCEL_RECOMMENDATION = config.FEATURE_ENABLED_CANCEL_RECOMMENDATION;
        Configuration.FEATURE_ENABLED_PENDING_TAB = config.FEATURE_ENABLED_PENDING_TAB;
        Configuration.FEATURE_ENABLED_SL_TP = config.FEATURE_ENABLED_SL_TP;
        Configuration.FEATURE_ENABLED_PASSWORD_ON_SUBMIT = config.FEATURE_ENABLED_PASSWORD_ON_SUBMIT;
        Configuration.FEATURE_ENABLED_ACCEPT_BY_DEALER = config.FEATURE_ENABLED_ACCEPT_BY_DEALER;
        Configuration.FEATURE_ENABLED_ACCOUNTS_REPORT = config.FEATURE_ENABLED_ACCOUNTS_REPORT;
        Configuration.FEATURE_ENABLED_QNB_CUSTOMER_NUMBER = config.FEATURE_ENABLED_QNB_CUSTOMER_NUMBER;
        Configuration.FEATURE_ENABLED_MANUAL_ACCOUNTS = config.FEATURE_ENABLED_MANUAL_ACCOUNTS;
        Configuration.FEATURE_ENABLED_CUD_CLIENT = config.FEATURE_ENABLED_CUD_CLIENT;
        Configuration.FEATURE_ENABLED_CUD_PORTFOLIO = config.FEATURE_ENABLED_CUD_PORTFOLIO;
        Configuration.FEATURE_ENABLED_CUD_STOCK = config.FEATURE_ENABLED_CUD_STOCK;
        Configuration.FEATURE_ENABLED_CUD_TRADE = config.FEATURE_ENABLED_CUD_TRADE;
        Configuration.FEATURE_ENABLED_CUD_ORDER = config.FEATURE_ENABLED_CUD_ORDER;
        Configuration.FEATURE_ENABLED_PUSHTRADE_ACTIONS = config.FEATURE_ENABLED_PUSHTRADE_ACTIONS;
    }
}

export default Configuration;