import React from 'react';
import { Redirect } from 'react-router-dom';
import PushtradeApiClient from './pushtradeApiClient';

export class AuthRequired extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        loading: true,
        redirect: false,
      };
  }

  componentDidMount() {

    const token = localStorage.getItem('token');

    if (!token || token === 'undefined' || typeof (token) === 'undefined') {
      console.log('[AuthRequired] NO TOKEN! loading: false, redirect: true');
      this.setState({ loading: false, redirect: true });
    }else {
      this.setState({ loading: false });
    }

    // let apiClient = new PushtradeApiClient();
    // apiClient.get('user/me').then(res => {
    //     if (res == null) {
    //         this.setState({ loading: false, redirect: true });
    //     }
    //     else {

    //         localStorage.setItem('user', JSON.stringify(res.data.data));

    //         // console.log('[LOG] localStorage.getItem(user) = ' + localStorage.getItem('user'));
    //         if (localStorage.getItem('user') === 'undefined' || typeof (localStorage.getItem('user')) === 'undefined') {
    //           // console.log('[LOG] loading: false, redirect: true');
    //           this.setState({ loading: false, redirect: true });
    //         }else {
    //           this.setState({ loading: false });
    //         }

    //         //this.setState({ loading: false });
    //     }
    // })
    // .catch(err => {
    //     console.error(err);
    //     this.setState({ loading: false, redirect: true });
    // });

  }

  render() {
    const { loading, redirect } = this.state;
      if (loading) {
        return null;
      }
      if (redirect) {
        return <Redirect to={this.props.redirectTo} />;
      }
      return (this.props.orRender);
  }
}

export default AuthRequired;
