import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import { HashRouter } from 'react-router-dom';
import './assets/base.scss';
import Main from './PushtradePages/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Configuration from './PushtradePages/configuration';
const store = configureStore();
const rootElement = document.getElementById('root');

// Function to set logo CSS variable based on configuration
const setCSSVariables = () => {

    const logo = Configuration.B2B_PARTNER_LOGO;

    if (logo) {
        const logoUrl = `url('/images/logos/${logo}')`;
        console.log(`logoUrl: ${logoUrl}`);
        document.documentElement.style.setProperty('--logo-url', logoUrl);
    }
};

// Function to set favicon based on configuration
const setFavicon = () => {
    const favicon = Configuration.B2B_PARTNER_FAVICON;

    if (favicon) {
        const faviconUrl = `${process.env.PUBLIC_URL}/${favicon}`;
        const faviconElement = document.getElementById('dynamic-favicon');
        if (faviconElement) {
            faviconElement.href = faviconUrl;
        }
    }
};

// Create a new configuration object
// const config = new Configuration();

// Set CSS variables based on configuration
setCSSVariables();
setFavicon();

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <HashRouter>
                <Component />
            </HashRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

serviceWorker.unregister();

